import maplibregl from 'maplibre-gl'
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder'

function highlight(element){
  let defaultOutline = element.style.outline;

  element.style.transition = "outline 0.5s";
  element.style.outline = '#d11415 solid 4px';

  setTimeout(function()
  {
      element.style.outline = defaultOutline;

  }, 2000);
}

// can be set by a script tag inside the wordpress shop
const useShopMode = (window as any).useShopMode as boolean

let coordinateInput: Element|undefined
if(useShopMode){
  coordinateInput = document.querySelector('.map-coordinates input')
  if(coordinateInput){
    console.log(coordinateInput)
    coordinateInput.setAttribute("readonly", "true")
    coordinateInput.addEventListener("click", ()=>{
      console.log("test")
      const container=map.getContainer()
      console.log(container)
      container.scrollIntoView({
        behavior: "smooth",
        block: "center"
      })
      highlight(container)
    })
  }
}

const map = new maplibregl.Map({
  container: 'map',
  style: 'https://tiles.kartensachen.de/styles/dark3/style.json',
  center: [11.41142, 53.62346],
  zoom: 14.84,
  antialias: true,
  customAttribution: ['Style © <a href="https://kleiner-sonnenschein.de/" target="_blank">Kleiner Sonnenschein</a>']
})

var geocoder_api = {
  forwardGeocode: async config => {
    const features = []
    try {
      let request =
        'https://nominatim.openstreetmap.org/search?q=' +
        config.query +
        '&format=geojson&polygon_geojson=1&addressdetails=1'
      const response = await fetch(request)
      const geojson = await response.json()
      for (let feature of geojson.features) {
        let center = [
          feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
          feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2
        ]
        let point = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: center
          },
          place_name: feature.properties.display_name,
          properties: feature.properties,
          text: feature.properties.display_name,
          place_type: ['place'],
          center: center
        }
        features.push(point)
      }
    } catch (e) {
      console.error(`Failed to forwardGeocode with error: ${e}`)
    }

    return {
      features: features
    }
  }
}
map.addControl(
  new MaplibreGeocoder(geocoder_api, {
    maplibregl: maplibregl
  })
)

map.addControl(new maplibregl.NavigationControl({}))

map.addControl(
  new maplibregl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: false,
    showUserLocation: false,
    showAccuracyCircle: false
  })
)

var center = map.getCenter()
var marker = new maplibregl.Marker({
  element: document.getElementById('shirt'),
  draggable: false
})
  .setLngLat(center)
  .addTo(map)

function updateMarkerPosition () {
  var center = map.getCenter()
  marker.setLngLat(center)
}
updateMarkerPosition()

map.on('move', function () {
  updateMarkerPosition()
})
map.on('moveend', function () {
    const {
    topLeft,
    bottomRight
  } = getMarkerCoordinates()
  const el = document.querySelector('.map-coordinates input')
  if (el) {
    const output = { topLeft, bottomRight }
    const json = JSON.stringify(output, function (key, value) {
      // limit precision of floats
      if (typeof value === 'number') {
        return parseFloat(value.toFixed(5))
      }
      return value
    })
    el.setAttribute('value', json)
  }
})

if (!useShopMode) {
  map.on('mousemove', function (e) {
    const {
      topLeft,
      bottomRight,
      center,
      markerDimensions
    } = getMarkerCoordinates()

    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [topLeft.lng, topLeft.lat],
                [bottomRight.lng, topLeft.lat],
                [bottomRight.lng, bottomRight.lat],
                [topLeft.lng, bottomRight.lat],
                [topLeft.lng, topLeft.lat]
              ]
            ]
          }
        }
      ]
    }
    const text = JSON.stringify(geojson)

    document.getElementById('info').innerHTML =
      // e.point is the x, y coordinates of the mousemove event relative
      // to the top-left corner of the map
      JSON.stringify(e.point) +
      '<br />' +
      // e.lngLat is the longitude, latitude geographical position of the event
      JSON.stringify(e.lngLat.wrap()) +
      '<br />' +
      JSON.stringify(center) +
      '<br />' +
      JSON.stringify(topLeft.wrap()) +
      '<br />' +
      JSON.stringify(bottomRight.wrap()) +
      '<br />' +
      `<a href="data:application/geo+json;charset=utf-8,${encodeURIComponent(
        text
      )}" download="shirt.geojson">Get shirt GeoJson</a>` +
      '<br />' +
      `<a href="https://tiles.kartensachen.de/styles/dark2/static/${topLeft.lng.toFixed(
        4
      )},${topLeft.lat.toFixed(4)},${bottomRight.lng.toFixed(
        4
      )},${bottomRight.lat.toFixed(4)}/${markerDimensions.width *
        2}x${markerDimensions.height * 2}@2x.png">Get static image</a>` +
      '<br />' +
      `<a href="https://tiles.kartensachen.de/styles/dark2/static/${topLeft.lng},${topLeft.lat},14/${markerDimensions.width}x${markerDimensions.height}@2x.png">Get static image</a>`
  })
}

function getMarkerCoordinates () {
  const markerDimensions = {
    // px
    width: 222,
    height: 327
  }
  const canvas = map.getCanvas()
  const rect = canvas.getBoundingClientRect()
  const center = [rect.width / 2, rect.height / 2]
  const topLeft = map.unproject([
    center[0] - markerDimensions.width / 2,
    center[1] - markerDimensions.height / 2
  ])
  const bottomRight = map.unproject([
    center[0] + markerDimensions.width / 2,
    center[1] + markerDimensions.height / 2
  ])
  return { topLeft, bottomRight, center, markerDimensions }
}
